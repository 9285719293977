import React from 'react';

const Terms = () => {
	return (
		<div className="terms">
			<div className="termsWrapper">
				<h1>PRIVACYVERKLARING / PRIVACY STATEMENT</h1>
				<p>We vinden de bescherming van je privacy uiterst belangrijk. We willen je zoveel mogelijk informeren, respecteren en controle geven over wat er met je persoonsgegevens gebeurt. Hieronder vind je informatie over welke gegevens wij verzamelen, waarom we dat doen, hoelang wij ze bewaren, wat je privacy-rechten zijn en hoe je deze kan uitoefenen. Dit privacy beleid geldt alleen voor de persoonsgegevens die wij als verwerkingsverantwoordelijke verwerken. Onder &lsquo;verwerking van persoonsgegevens&rsquo; verstaan we elke verwerking van gegevens die je als natuurlijke persoon kunnen identificeren.</p>
				<p>We view protecting your privacy as a matter of the utmost importance. We would like to keep you informed as best as possible, respect you and give you control over what happens to your personal information. Set out below you will find information about what data we collect, why we do so, how long we keep it, what your privacy rights are and how you can exercise these rights.This privacy policy only applies in respect of the personal information, which we process as the data controller.&lsquo;Processing personal information&rsquo; is understood to mean any processing of information, which may identify you as a natural person.</p>
				<ol>
					<li><h2> WIE ZIJN WE (VERWERKINGSVERANTWOORDELIJKE)? / WHO ARE WE (DATA CONTROLER)?</h2></li>
				</ol>
				<p>PRIME SAFETY BV aan vennootschap naar Belgisch recht met maatschappelijke zetel te Marcottedreef 322950 Kapellen en met ondernemingsnummer BE 0828.216.880;</p>
				<p>PRIME SAFETY BV a company incorporated under Belgian law having its registered office in Marcottedreef 322950 Kapellen and with enterprise number BE 0828.216.880.</p>
				<ol start="2">
					<li><h2>WAAROM VERWERKEN WIJ UW PERSOONSGEGEVENS? / WHY DO WE PROCESS YOUR PERSONAL DATA?</h2></li>
				</ol>
				<p>We verwerken persoonsgegevens voor diverse doeleinden, waarbij wij telkens enkel de gegevens verwerken die noodzakelijk zijn om het beoogde doel te realiseren. Zo kunnen wij persoonsgegevens gebruiken wanneer dit noodzakelijk is:</p>
				<ul>
					<li> in het kader van de voorbereiding, uitvoering of be&euml;indiging van onze activiteiten;</li>
					<li> om te voldoen aan de wettelijke of reglementaire bepalingen waaraan wij zijn onderworpen; en/of</li>
					<li> voor de behartiging van onze gerechtvaardigde belangen.</li>
				</ul>
				<p>Is het verwerken van jouw persoonsgegevens niet noodzakelijk voor &eacute;&eacute;n van deze drie redenen, dan vragen wij steeds je toestemming om je persoonsgegevens te mogen verwerken.</p>
				<p>Wij verzamelen persoonsgegevens voor de volgende concrete doeleinden:</p>
				<ul>
					<li> Om je aanvraag, verzoek of aanmelding in behandeling nemen. Als je onze website bezoekt om informatie te verzamelen en/of aan te vragen dan hebben kunnen we je adresgegevens nodig hebben. Alle informatie die wij over je ontvangen, gebruiken we alleen om je van de gevraagde informatie te voorzien, op de wijze zoals jij dat wilt;</li>
					<li> Om onze producten en diensten te blijven verbeteren;</li>
					<li> Om onze prestaties op te volgen;</li>
					<li> Om te voldoen aan wettelijke verplichtingen;</li>
				</ul>
				<p>We process personal information for a variety of purposes, and in each case we process the data, which is required to achieve the intended goal. In this way we can use personal information when this is necessary:</p>
				<ul>
					<li> as part of the preparation, implementation or termination of our activities;</li>
					<li> to meet the legal or regulatory provisions to which we are subject; and/or</li>
					<li> for the furtherance of our legitimate interests.</li>
				</ul>
				<p>If processing your personal information is not necessary for one of these three reasons, then we always ask for your consent to be permitted to process your personal information.</p>
				<p>We collect personal information for the following specific purposes:</p>
				<ul>
					<li>For handling your demand, request or regitration. If you visit our web site to collect and/or request information then we may need your address details. All information, which we receive about you we only use to provide you with the requested information, in the way that you want;</li>
					<li>To improve our products and services;</li>
					<li>To monitor our performance;</li>
					<li>To meet our legal obligations</li>
				</ul>
				<ol start="3">
					<li><h2> WELKE PERSOONSGEGEVENS KUNNEN WE VERWERKEN? / WHAT PERSONAL INFORMATIE CAN WE PROCESS?</h2></li>
				</ol>
				<p>We verwerken de persoonsgegevens die je ons zelf geeft. Wij kunnen je persoonsgegevens toewijzen voor het gebruik van onze producten en diensten. Daarnaast kunnen onze systemen ook persoonsgegevens registreren die worden gegenereerd tijdens jouw gebruik van onze producten en diensten met inbegrip van het gebruik door eventuele eindgebruikers. Wij kunnen ook persoonsgegevens verwerven via gespecialiseerde bedrijven om onze dienstverlening nog beter af te stemmen op je behoeften. Die gespecialiseerde bedrijven die uw persoonsgegevens doorgeven zijn verantwoordelijk voor het hebben van de juiste wettelijke basis voor het verwerken van persoonsgegevens en voor het overdragen ervan aan ons zodat we ze kunnen gebruiken voor eigen doeleinden.</p>
				<p>Wij onderscheiden in onze systemen verschillende types persoonsgegevens, die met elkaar gecombineerd kunnen worden:</p>
				<ul>
					<li> Gebruikersgegevens: wij onderscheiden de persoonsgegevens die je als gebruiker van onze producten en diensten kunnen identificeren, de persoonsgegevens die ons toelaten contact met je op te nemen, de persoonsgegevens die je persoonlijke kenmerken aanduiden dan wel je leef- en consumptiegewoonten of je facturatie- en betalingsgegevens betreffen en alle andere voorkeuren die je kenbaar maakt aan ons;</li>
					<li> Technische gegevens: deze hebben wij nodig om een goede werking van onze producten en diensten te kunnen realiseren. Bijvoorbeeld: het model en servicenummer van je Digibox of de softwareversie die je gebruikt op &eacute;&eacute;n van onze apps;</li>
					<li> Verkeersgegevens: deze bijzondere technische gegevens hebben we nodig om je verkeer over elektronische communicatienetwerken af te wikkelen;</li>
					<li> Locatiegegevens: met deze gegevens kunnen we de locatie van bepaalde toestellen vaststellen hetgeen wij gebruiken voor netwerkplanning en &ndash;beheer;</li>
					<li> Gegevens over je gebruik: de gegevens die wij ontvangen wanneer je onze producten en diensten gebruikt;</li>
					<li> Cookies: wij kunnen gebruikmaken van cookies wanneer je onze e-mails opent en/of wanneer je onze websites bezoekt of apps gebruikt. Cookies zijn stukjes informatie die op je eigen computer of mobiel toestel bewaard worden en die meestal worden gebruikt om het gebruiksgemak van websites en apps te optimaliseren.</li>
				</ul>
				<p>We process the personal information, which you yourself pass on to us. We may assign to you personal information for using our products and services. In addition, our systems may also record personal information, which is generated while you are using our products and services including use by end users.We may purchase personal information from specialist companies to better customize our service provision to your needs. The specialist companies that transfers your personal data are responsible for having the appropriate legal basis for processing personal data and for transferring it to us so that we can use it for our own purposes.</p>
				<p>We distinguish in our systems between different types of personal information, which can be combined with one another:</p>
				<ul>
					<li>User data: we distinguish between the personal information, which can identify you as a user of our products and services, the personal information, which allows us to contact you, the personal information, which designates your personal characteristics or your lifestyle and consumer habits or relate to your invoicing and payment data and all other preferences, which you disclose to us;</li>
					<li>Technical data: we need this to be able to ensure the smooth operation of our products and services;</li>
					<li>Traffic date: we need this special technical data to handle traffic over electronic communication networks;</li>
					<li>Location data: using this data we are able to determine the location of your devices which we use for network planning and management;</li>
					<li>Data about your usage: the data, which we receive when you use our products and services;</li>
					<li>Cookies: we may use cookies when you open our emails and/or when you visit our websites or mobile apps. Cookies are pieces of information that are stored on your own computer or mobile device and that are usually used to optimize the ease of use of websites and apps.</li>
				</ul>
				<ol start="4">
					<li> <h2>TRANSFER VAN GEGEVENS / TRANSFER OF PERSONAL DATA</h2></li>
				</ol>
				<p>Wij dragen geen persoonsgegevens over aan derde partijen zonder jouw toestemming tenzij:</p>
				<ul>
					<li> aan onze rechtsopvolgers en verbonden vennootschappen;</li>
					<li> dit nodig is voor onze dienstverlening;</li>
					<li> er een wettelijke verplichting is;</li>
					<li> er een gerechtvaardigd belang is voor ons of de betrokken derde;</li>
					<li> je ons daar toelating voor geeft.</li>
				</ul>
				<p>We do not transfer sell any personal information to third parties without your consent unless:</p>
				<ul>
					<li>it is to our legal successors and to affiliated companies;</li>
					<li>this is necessary to provide our service;</li>
					<li>there is a legal obligation;</li>
					<li>there is a legitimate interest for us or the third party involved;</li>
					<li>you give us consent to do so;</li>
				</ul>
				<ol start="5">
					<li><h2>WAT ZIJN UW RECHTEN? / WHAT ARE YOUR RIGHTS?</h2></li>
				</ol>
				<ul>
					<li> Recht van inzage: je hebt het recht om op ieder ogenblik te vernemen of wij al dan niet je persoonsgegevens verwerken;</li>
					<li> Recht op verbetering van persoonsgegevens: Je hebt het recht om onvolledige, foutieve, ongepaste of verouderde persoonsgegevens onverwijld te laten rechtzetten.</li>
					<li> Recht op vergetelheid: je hebt het recht om je persoonsgegevens zonder onredelijke vertraging te laten verwijderen;</li>
					<li> Recht op beperking van de verwerking: je hebt het recht om zonder onredelijke vertraging de beperking van de verwerking van je persoonsgegevens te verkrijgen;</li>
					<li> Recht om bezwaar te maken tegen de verwerking van je persoonsgegevens: je hebt het recht om op grond van je bijzondere situatie bezwaar te maken tegen de verwerking van je persoonsgegevens;</li>
					<li> Opt-out voor direct marketing: hebt altijd het recht om jezelf, zonder dat je daarvoor een reden moet opgeven, te verzetten tegen het gebruik van je persoonsgegevens voor direct marketing-doeleinden.</li>
					<li>Right of access: you are entitled to find out at any time whether or not we process your personal information;</li>
					<li>Right to update personal information: you are entitled to have incomplete, incorrect, inappropriate or out-of-date personal information corrected without delay.</li>
					<li>Right to erasure: you are entitled to have your personal information deleted without unreasonable delay;</li>
					<li>Right to restrict the processing of data: you are entitled without unreasonable delay to obtain a restriction on the processing of your personal information;</li>
					<li>Right to object against the processing of your personal information :you are entitled to lodge an objection against the processing of your personal information on grounds of your special situation;</li>
					<li>Opt-out for direct marketing: you are always entitled to oppose the use of your personal information for direct marketing purposes without having to specify a reason for this.</li>
				</ul>
				<ol start="6">
					<li><h2>HOE OEFENT U UW PRIVACY-RECHTEN UIT? / HOW DO YOU EXERCISE YOUR PRIVACY RIGHTS</h2></li>
				</ol>
				<p>Verstuur uw verzoek per email via info@primesafety.be of naar het adres van de maatschappelijke zetel van de verwerkingsverantwoordelijke. Om je privacy-rechten uit te oefenen, en om elke ongeoorloofde openbaarmaking van je persoonsgegevens te voorkomen, dienen wij je identiteit te verifi&euml;ren. Bij twijfel of onduidelijkheid zullen wij jou eerst nog om bijkomende informatie vragen (bij voorkeur een kopie van de voorzijde van je identiteitskaart). Indien wij jou niet met zekerheid kunnen identificeren, zullen wij geen gevolg geven aan je verzoek. Je kan je privacy-rechten gratis uitoefenen, tenzij je verzoek kennelijk ongegrond of buitensporig is, met name vanwege het repetitieve karakter. Wanneer je je verzoek elektronisch indient, wordt de informatie indien mogelijk elektronisch verstrekt, tenzij je dit anderszins verzoekt. In ieder geval bezorgen wij jou een beknopt, transparant, begrijpelijk en makkelijk toegankelijk antwoord. Wij reageren zo snel mogelijk op je verzoek, en in ieder geval binnen een maand na ontvangst van je verzoek. Afhankelijk van de complexiteit van de verzoeken en van het aantal verzoeken, kan die termijn desnoods nog eens twee maanden worden verlengd. Bij een verlenging van de termijn stellen wij jou hier binnen &eacute;&eacute;n maand na ontvangst van het verzoek in kennis.</p>
				<p>Send your request by email to info@primesafety.be or to the registered office of the controler. To exercise your privacy rights, and to prevent any unauthorized disclosure of your personal information, we need to verify your identity. In case of doubt or unresolved issues we will first ask you for additional information (preferably a copy of the front of your identity card). If we cannot confirm your identity with certainty, we will not process your request.You can exercise your privacy rights free of charge unless your request is manifestly unfounded or disproportionate, in particular due to its repetitive nature.When you submit your request electronically, where possible the information is provided electronically, unless you request it otherwise. In any case we will provide you with a brief, transparent, intelligible and easy-to-access response.We will respond as quickly as possible to your request, and in any case within one month of receipt of your request. Depending on the complexity of the requests and the number of requests, this period may be extended for a further two months, if required. In case of an extension of the period, we will notify you of this within one month of receipt of the request.</p>
				<ol start="7">
					<li><h2>HOE LANG BEWAREN WIJ PERSOONSGEGEVENS? / HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?</h2></li>
				</ol>
				<p>Wij mogen persoonsgegevens niet langer bewaren dan nodig is om het doel te realiseren waarvoor we ze verzamelen. De bewaartermijn verschilt dus per doel. Soms kan de termijn langer zijn, bijvoorbeeld om onze wettelijke verplichtingen na te komen, bijvoorbeeld om tegemoet te komen aan onze arbeidsrechtelijke, boekhoudkundige en fiscale verplichtingen of uit de juridische noodzaak om bepaalde gegevens als bewijs te bewaren. tot maximaal 10 jaar na be&euml;indiging van je relatie met de verwerkingsverantwoordelijke. Gearchiveerde gegevens zijn slechts beperkt toegankelijk. Na afloop van de toepasselijke bewaartermijn(en) worden persoonsgegevens gewist of geanonimiseerd.</p>
				<p>We are not permitted to keep your personal information for longer than is necessary to achieve the purpose for which we are collecting it. The retention period varies for each purpose. Sometimes the period may also be longer, for example, to observe our legal obligations, e.g. to meet our accounting and tax obligations, for example, we are obliged to keep your invoicing data for a maximum of 7 years) or as a legal requirement to retain certain data (in particular your contract, invoices and correspondence in connection with complaints about this) as evidence in case of disputes, for up to a maximum of 10 years following termination of your contract. Archived data is only accessible to a limited extent. After expiry of the applicable retention period(s), personal information is deleted or anonymized.</p>
				<ol start="8">
					<li><h2>HOE BEVEILIGEN WIJ JE PERSOONSGEGEVENS? / HOW DO WE PROTECT YOUR PERSONAL INFORMATION?</h2></li>
				</ol>
				<p>We werken hard om je persoonsgegevens en privacy te beschermen. Onze medewerkers worden opgeleid om correct om te gaan met vertrouwelijke gegevens. In het kader van ieder project dat een verwerking van persoonsgegevens beoogt, wordt eerst een inschatting gemaakt op het vlak van veiligheid en de bescherming van persoonsgegevens, waarbij jouw belangen voorop staan. Voor de beveiliging van je gegevens doen wij beroep deskundige personen die eveneens die instaan voor de veiligheid van netwerken, infrastructuur en informatiesystemen. Bovendien gebruiken wij allerlei technische maatregelen om je persoonsgegevens te beschermen tegen ongeoorloofde toegang, ongeoorloofd gebruik en verlies of diefstal van je gegevens. De software op onze producten wordt voortdurend up-to-date gehouden. Het aantal medewerkers van ons bedrijf dat toegang heeft tot je persoonsgegevens is beperkt. Er wordt hen enkel toegang tot je persoonsgegevens verleend voor zover ze die informatie nodig hebben om hun taken naar behoren uit te voeren. Voor zover persoonsgegevens buiten de Europese Unie worden verwerkt, zorgen wij er via contractuele of andere maatregelen voor dat die gegevens daar een passend beschermingsniveau genieten vergelijkbaar met de bescherming die zij in de Europese Unie zouden genieten, conform de Europese regelgeving.</p>
				<p>We work hard to protect your personal information and privacy. Our staff are trained in how to correctly handle confidential data. As part of any project the aim of which is to process personal information, an assessment is first made in relation to security and the protection of personal information, in which case your interests take precedence. To protect your personal data we have employed skilled individuals who are also responsible for the security of networks, infrastructure and information systems. Furthermore, we use all kinds of technical measures to protect your personal information against unauthorized access, unauthorized use and loss or theft of your data. The software on our products is constantly kept up-to-date. The number of employees in our company that has access to your personal information is restricted. They are only granted access to your personal information insofar as they require this information to complete their duties properly.Insofar as personal information is processed outside of the European Union, through contractual or other measures we ensure that this data is granted an appropriate level of protection there comparable with the protection, which it would be granted within the European Union, in accordance with European regulations.</p>
				<ol start="9">
					<li> <h2>ACTUALISERING EN TOEZICHTHOUDENDE OVERHEID / UPDATE AND SUPERVISORY AUTHORITY</h2></li>
				</ol>
				<p>Wij kunnen dit privacy beleid van tijd tot tijd wijzigen. Wij nodigen je dan ook uit om steeds de laatste versie van dit beleid te raadplegen op onze website.</p>
				<p>De Gegevensbeschermingsautoriteit is een onafhankelijk orgaan dat erop toeziet dat uw persoonsgegevens volgens de wet worden verwerkt. Indien u een klacht hebt in verband met de verwerking van je persoonsgegevens door ons of indien u een procedure wenst op te starten voor een bemiddeling, dan kan u zich wenden tot/ <a href="https://www.gegevensbeschermingsautoriteit.be/burger/acties/klacht-indienen">https://www.gegevensbeschermingsautoriteit.be/burger/acties/klacht-indienen</a></p>
				<p>We may amend this privacy policy from time to time. We invite you to always check out the most recent version of this policy on our website.</p>
				<p>The Data Protection Authority is an independent body that ensures that your personal data are processed in accordance with the law. If you have a complaint regarding the processing of your personal data by us or if you wish to initiate proceedings for mediation, you can contact the authority via</p>
				<p><a href="https://www.gegevensbeschermingsautoriteit.be/burger/acties/klacht-indienen">https://www.gegevensbeschermingsautoriteit.be/burger/acties/klacht-indienen</a></p>
			</div>
		</div>
	);
};

export default Terms;
