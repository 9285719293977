import React from 'react';
import Navigation from '../components/navigation.js';
import Footer from '../components/footer.js';
import MetaData from '../components/partials/metaData';
import Terms from '../components/terms/terms';
import '../styles/general.scss';

const privacyPolicy = () => {
	return (
		<>
			<MetaData title={'Privacy policy'} description={'A policy for you privacy'} />
			<Navigation />
			<Terms />
			<Footer />
		</>
	);
};

export default privacyPolicy;
